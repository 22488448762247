<template>
    <v-container fluid >
        <Formulario :getParams="this.params"></Formulario>
    </v-container>
</template>

<script>
//const Formulario = () => import("./components/Formulario.vue");
import Formulario from "./components/Formulario.vue";
import Auth from "@/services/auth";
export default {
    name: "AdministracionUnidadesOperativasNuevo",
    data() {
        return {
            permisos: [],
            params: {
                unidad_operativa_id: null,
            }
        }
    },
    components: {
        Formulario
    },
    methods: {
        regresar() {
            this.$router.go(-1)
        }
    },
    created() {
        Auth.checkPermisos("/administracion/unidades_operativas").then((res) => {
            this.permisos = res;
            if (!this.permisos[2]) {
                this.regresar();
            }
        });
    }
}
</script>

<style>

</style>
